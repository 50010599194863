import * as moment from 'moment';
import * as _ from 'lodash';

export class User {
  firstName: string;
  lastName: string;
  email: string;
  photoUrl: string;

  toLocalStorage() {
    localStorage.setItem('user.firstName', this.firstName);
    localStorage.setItem('user.lastName', this.lastName);
    localStorage.setItem('user.email', this.email);
    localStorage.setItem('user.photoUrl', this.photoUrl);
  }

  static fromLocalStorage() {
    const object = new User();

    object.firstName = localStorage.getItem('user.firstName');
    object.lastName = localStorage.getItem('user.lastName');
    object.email = localStorage.getItem('user.email');
    object.photoUrl = localStorage.getItem('user.photoUrl');
    return object;
  }

  clear() {
    localStorage.removeItem('user.firstName');
    localStorage.removeItem('user.lastName');
    localStorage.removeItem('user.email');
    localStorage.removeItem('user.photoUrl');
  }
}

export class UserCredentials {
  id_token: string;
  expires_in: number;
  expires_at: moment.Moment;

  toLocalStorage() {
    localStorage.setItem('credentials.id_token', this.id_token);
    localStorage.setItem('credentials.expires_in', this.expires_in.toString());
    localStorage.setItem('credentials.expires_at', moment().add(this.expires_in, 'seconds').format());
  }

  clear() {
    localStorage.removeItem('credentials.id_token');
    localStorage.removeItem('credentials.expires_in');
    localStorage.removeItem('credentials.expires_at');
  }

  static fromLocalStorage() {
    const object = new UserCredentials();

    object.id_token = localStorage.getItem('credentials.id_token');
    object.expires_in = parseInt(localStorage.getItem('credentials.expires_in'));
    object.expires_at = moment(localStorage.getItem('credentials.expires_at'));
    return object;
  }

  isValid() {
    return !_.isNil(this.id_token)
      && !_.isNil(this.expires_in)
      && !_.isNil(this.expires_at)
      && this.expires_at > moment();
  }
}
