import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface IEnv {
  name: string;
  code: string;
  apiUrl: string;
}

const ENVIRONMENTS = [
  {
    name: 'Qualification',
    code: 'qualif',
    apiUrl: 'https://qualif-backend-teleo-rem.hp.m-ve.com/api/v3/monitoring'
  },
  {
    name: 'Recette',
    code: 'rec',
    apiUrl: 'https://rec-backend-teleo-rem.hp.m-ve.com/api/v3/monitoring'
  },
  {
    name: 'Production',
    code: 'prod',
    apiUrl: 'https://backend-teleo-rem.m-ve.com/api/v3/monitoring'
  },
  {
    name: 'Local',
    code: 'local',
    apiUrl: 'http://localhost:8000/api/v3/monitoring'
  },
];

@Injectable({
  providedIn: "root"
})
export class EnvironmentService {
  environmentSubject = new BehaviorSubject<IEnv>(ENVIRONMENTS[0]);
  environment$ = this.environmentSubject.asObservable();

  autoRefreshSubject = new BehaviorSubject<boolean>(false);
  autoRefresh$ = this.autoRefreshSubject.asObservable();

  constructor() {
  }

  setAutoRefresh(value: boolean) {
    this.autoRefreshSubject.next(value);
    localStorage.setItem('navbar.autorefresh', value === true ? 'true' : 'false');
  }

  setEnvironment(env: IEnv) {
    this.environmentSubject.next(env);
    localStorage.setItem('navbar.environment', env.code);
  }

  getAvailableEnvironments(): IEnv[] {
    return ENVIRONMENTS;
  }
}
