<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <div class="navbar-brand-center">
        <div class="navbar-header" style="width: 100% !important">
          <ul class="navbar-nav">
            <li class="nav-item" style="margin-left: 42px">
              <div class="logo">
                <a class="logo-text" [routerLink]="['/']">
                  <span class="text">TELEO Monitoring</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <div class="custom-switch custom-control-inline mb-1 mb-xl-0">
              <input type="checkbox" class="custom-control-input" id="custom-switch-1" [checked]="isAutoRefreshActivated" (change)="toggleAutoRefresh($event)">
              <label class="custom-control-label mr-1" for="custom-switch-1">
                <span>Auto-refresh</span>
              </label>
            </div>
          </li>

          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
               style="border: 1px solid black; border-radius: 5px;"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <span class="selected-language d-md-flex d-none">
                <b>{{ currentEnvironment.name | uppercase }}</b>
              </span>
            </a>

            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a *ngFor="let env of environments" class="dropdown-item" href="javascript:;" (click)="selectEnvironment(env)">
                <span class="font-small-3">{{ env.name | uppercase }}</span>
              </a>
            </div>
          </li>



          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{ user?.firstName }} {{ user?.lastName }}</span><span
                class="text-right text-muted font-small-3">Connected</span>
              </div>
              <img class="avatar" [src]="user?.photoUrl" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
                 ngbDropdownMenu>
              <a class="dropdown-item" (click)="logOut()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
