import { Routes, RouterModule } from '@angular/router';

//Route for landing layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  // },
  {
    path: 'app',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
];
