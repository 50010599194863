import { ActionReducerMap } from '@ngrx/store';



export interface AppState {

}

export const appReducer: ActionReducerMap<AppState> = {

};
