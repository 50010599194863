import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, UserCredentials } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject = new BehaviorSubject<User>(null);
  user$ = this.userSubject.asObservable();

  constructor(
    public router: Router,
    private http: HttpClient,
  ) {
  }

  setUser(user: gapi.auth2.GoogleUser) {
    const credentials = new UserCredentials();
    const authResponse = user.getAuthResponse();

    credentials.id_token = authResponse.id_token;
    credentials.expires_at = moment(authResponse.expires_at);
    credentials.expires_in = authResponse.expires_in;

    credentials.toLocalStorage();

    const _user = new User();
    const profile = user.getBasicProfile();

    _user.firstName = profile.getGivenName();
    _user.lastName = profile.getFamilyName();
    _user.photoUrl = profile.getImageUrl();
    _user.email = profile.getEmail();

    _user.toLocalStorage();

    this.userSubject.next(_user);
  }

  async logout() {
    UserCredentials.fromLocalStorage().clear();
    User.fromLocalStorage().clear();
    this.router.navigate(['pages/login']);
  }

  async isAuthenticated() {
    return UserCredentials.fromLocalStorage().isValid();
  }
}
