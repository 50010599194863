// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://qualif-backend-teleo-rem.hp.m-ve.com/api/v3/monitoring',
  clientId: '251853433236-qj0m72dtav96m3shi51cuc4q85rfs75r.apps.googleusercontent.com',
  clientSecret: '82Uf8b8B2ZmWqMvSdrmKxJaT'
};
