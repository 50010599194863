import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { User } from '../models';
import { EnvironmentService } from '../services/environment.service';
import * as _ from 'lodash';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  transparentBGClass = "";
  hideSidebar: boolean = true;
  layoutSub: Subscription;
  configSub: Subscription;
  user: User = null;

  currentEnvironment = null;
  isAutoRefreshActivated = false;
  environments = []

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  public config: any = {};

  constructor(
    private envService: EnvironmentService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService, private cdr: ChangeDetectorRef) {

    this.environments = this.envService.getAvailableEnvironments();
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

    this.user = User.fromLocalStorage();
  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }

    const savedEnvironment = localStorage.getItem('navbar.environment');

    if (!_.isNil(savedEnvironment)) {
      console.log('savedEnvironment', savedEnvironment);
      console.log('environments', this.environments);
      this.currentEnvironment = this.environments.find((value) => value.code === savedEnvironment);
      this.envService.setEnvironment(this.currentEnvironment);
    }

    this.envService.environment$.subscribe((env) => {
      this.currentEnvironment = env;
    });
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  async logOut() {
    await this.authService.logout();
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }
  }

  selectEnvironment(env: any) {
    // this.currentEnvironment = env;
    this.envService.setEnvironment(env);

    window.location.reload();
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  toggleAutoRefresh(event) {
    this.isAutoRefreshActivated = !this.isAutoRefreshActivated;
    this.envService.setAutoRefresh(this.isAutoRefreshActivated);
  }
}
